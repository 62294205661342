<div class="error-screen">
  <div class="logo-container" style="margin-bottom: 96px;">
    <img class="logo" src="https://qa.turfassistant.com/assets/images/headerlogo.png" alt="Logo" />
  </div>

  <h1 class="error-message">Oops! Something went wrong.</h1>
  <p class="error-details">{{ dataFromParent }}</p>
  <div class="actions">
    <p class="contact-support">
      Click below to resolve the problem. If the issue persists, please contact support and send a screenshot.
    </p>
    <button style="z-index: 9999;" (click)="closePopup()">Click here to fix</button>
  </div>
  <div class="centered-image">
    <img style="height: 300px;
    position: relative;
    top: 50px;" src="assets/not-found.svg" alt="Preview not available" />
  </div>
</div>