<header>
  <div class="header-container">
    <div
      class="header-navbar"
      [ngStyle]="
        impersonateService?.impersonate && impersonateService?.user
          ? impersonateService?.impersonateHeaderStyle
          : {}
      "
    >
      <div class="header-elements-wrapper">

        <div class="col-auto header-logo-section pr-0">
          <div class="nav-header d-flex">
            <div class="nav-title">
              <a class="navbar-logo" href="javascript:void(0);">
                <div class="brand-logo">
                  <img
                    [src]="
                      (appService.sidebarOpen && !isTabletView)
                        ? '../../../assets/images/headerlogo.png'
                        : '../../../assets/images/impersonate-TurfwhiteLogo.svg'
                    "
                    alt="App Logo"
                    style="height: 55px"
                  />
                </div>
              </a>
            </div>
  
            <div class="toggled-icon">
              <ul class="nav navbar-nav">
                <li class="menu_cri">
                  <a
                    class="hidden-xs"
                    title="Collapse/Uncollapse Sidebar"
                    (click)="appService.toggleSidebar()"
                  >
                    <em class="fa fa-outdent" aria-hidden="true"></em>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-lg-2 col-sm-4 col-xs-12 d-flex pl-0">
         
          <div class="facility-dropdown drowpdown-scroll d-flex">
            <ul class="nav navbar-nav">
              <li class="dropdown d-flex">
                <a
                  id="facilityDropdown"
                  class="dropdown-items d-flex"
                  data-toggle="dropdown"
                  href="#"
                >
                  {{ currentFacility?.name }}
                  <i
                    class="fa fa-angle-down arrow-down pl-2"
                    aria-hidden="true"
                  ></i>
                </a>
                <ul class="dropdown-menu" style="position: absolute;
                will-change: transform;
                top: -20px;
                left: 0px;
                transform: translate3d(0px, 58px, 0px);
                padding: 8px 4px 0px 4px;
                border-radius: 4px; width: 315px !important;">
                  
                  <span *ngIf="facilityList?.length>1"
                    style="
                      display: block;
                      margin-bottom: 10px;
                      padding: 2px 5px 0px 5px;
                    "
                  >
                
  
                  <div class="formmodal_box search-tsp w-100 mt-0">
                    <div class="searchworker" style="border: 1px solid #cfd1d4">
                      <div class="form-group">
                        <input name="facilitySearch" type="text" 
                          class="SearchBox subpgsearch" [(ngModel)]="searchText.name"
                           placeholder="Search by facility name..." />
                        <span class="searchicon"></span>
                      </div>
                    </div>
                  </div>
  
                   
                  
                   
                  </span>
  
                  <li
                    style="
                      display: block !important;
                      padding: 8px 0px 16px 0px !important;
                      text-align: center !important;
                      color: #0c0c0cd6;
                    "
                    *ngIf="(facilityList | filterBy: searchText)?.length === 0 && facilityList?.length>1"
                  >
                    <i style="color: #999999;">No matching facility</i>
                  </li>
  
                 
  
                  <li
                    *ngFor="let facility of facilityList | filterBy: searchText"
                  >
                    <a
                      class="dropdown-link"
                      style="cursor: pointer"
                      (click)="chnageCurrentFacility(facility.id)"
                    >
                      {{ facility?.name }}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>

     
      <div
        class="d-flex justify-content-end container-wrapper"
      >
        
      <div class="whats-new-section" >
        <div class="facility-dropdown user-profile-section">
          <ul class="nav navbar-nav">
            <li class="dropdown d-flex">
              <a class="dropdown-items d-flex whatnew-link" href="javascript:volid();">What's New</a>
            </li>
          </ul>
        </div>
      </div>

        <div class="facility-dropdown user-profile-section d-flex">
          <ul class="nav navbar-nav">
            <li class="dropdown d-flex">
              <a
                id="currentLanguageDropdown"
                class="dropdown-items change-flag d-flex"
                data-toggle="dropdown"
                href="#"
              >
                <img
                  class="ml-2"
                  src="{{ currentLanguage?.img }}"
                  alt="user-image1"
                />
               
                <i
                  class="fa fa-angle-down arrow-down pl-2"
                  aria-hidden="true"
                ></i>
              </a>
              <ul class="dropdown-menu change-language" >
                <li>
                  <a class="dropdown-link" (click)="changeLanguage('en')">
                    <img
                      src="../../../../assets/images/brand/english_flag.png"
                      alt="user-image"
                      class="mr-2"
                    />
                    <span class="language-text"> English</span>
                  </a>
                </li>
                <li>
                  <a class="dropdown-link" (click)="changeLanguage('sp')">
                    <img
                      src="../../../../assets/images/brand/Spanish_flag.png"
                      alt="user-image"
                      class="mr-2"
                    />
                    <span class="language-text"> Spanish</span>
                  </a>
                </li>
                <li>
                  <a class="dropdown-link" (click)="changeLanguage('it')">
                    <img
                      src="../../../../assets/images/brand/Italian.png"
                      alt="user-image"
                      class="mr-2"
                    />
                    <span class="language-text"> Italian</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="facility-dropdown user-profile-section d-flex">
          <ul class="nav navbar-nav">
            <li class="dropdown d-flex">
              <a
                id="userLoginDropdown"
                class="dropdown-items d-flex"
                data-toggle="dropdown"
                href="#"
              >
                {{ loggedInUser?.firstname }} {{ loggedInUser?.lastname }}
                <i
                  class="fa fa-angle-down arrow-down pl-2"
                  aria-hidden="true"
                ></i>
                <img
                  [src]="profileimagespic"
                  [ngStyle]="{
                    transform: 'rotate(' + profilePicRotate + 'deg)'
                  }"
                  alt="user-image"
                  class="rounded-circle ml-2"
                />
              </a>
              <ul class="dropdown-menu user">
                <li class="user-header">
                  <img
                    style="height: 100px; width: 100px"
                    [src]="profileimagespic"
                    [ngStyle]="{
                      transform: 'rotate(' + profilePicRotate + 'deg)'
                    }"
                    class="img-circle"
                    alt="User Image"
                  />
                  <p>
                    {{ loggedInUser?.firstname }} {{ loggedInUser?.lastname }}
                    <small
                      >{{ 'Membersince' | translate }}
                      {{ loggedInUser?.created | date }}</small
                    >
                  </p>
                </li>
                <li class="user-body">
                  <!-- /.row -->
                </li>
                <!-- Menu Footer-->
                <li class="user-footer float-left w-100">
                  <div
                    class="col-md-12 pl-2 pr-2 d-flex justify-content-around"
                  >
                    <a
                      (click)="gotoEditProfile()"
                      class="button button-default"
                      >{{ 'EditProfile' | translate }}</a
                    >
                    <a
                      (click)="gotoChangePassword()"
                      class="button button-default"
                      [innerText]="'ChangePassword' | translate"
                      >Change Password</a
                    >
                    <a
                      (click)="signout()"
                      class="button button-default margin-right-0"
                      >{{ 'Signout' | translate }}</a
                    >
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!--End the header navbar section -->
  </div>
 
</header>

