import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/services/storage.serviceV2';
import { RequestService } from '../../../services/request.service';
import { endpoints } from '../../../constants/endpoints';
import { PageRoutes } from 'src/app/constants/route';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ImpersonateService } from 'src/app/services/impersonate.service';
import { AppService } from 'src/app/services/app.service';
import { LoaderService } from 'src/app/services/common-helper-service/loder.service';
import { Location } from '@angular/common';
import { GlobalSocketService } from 'src/app/services/api/socket.service';
import { FeatureService } from 'src/app/services/feature-service/feature.service';
import { ThemePalette } from '@angular/material/core';
import { notificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  loggedInUser: any = {};
  public profileimagespic: any = '../../../../assets/images/man.png';
  profilePicRotate = 0;
  profilePic;
  token: any;
  facilityList: any = [];
  currentFacility;
  Showcaser: any = [];
  User: any;
  environment: any;
  currentLanguage: any;
  observable: any;

  color: ThemePalette = 'accent';

  constructor(
    private storageService: StorageService,
    private router: Router,
    private requestService: RequestService,
    private translate: TranslateService,
    public impersonateService: ImpersonateService,
    public appService: AppService,
    public loaderService: LoaderService,
    private location: Location,
    private globalSocketService: GlobalSocketService,
    public featureService: FeatureService,
    private notificationService: notificationService
  ) {
    this.token = {
      authorization: this.storageService.authorizationToken,
    };
  }

  searchText: any = { name: '' };

  get isTabletView(): boolean {
    return window.innerWidth < 1024;
  }

  updateOptIn(value) {
    this.requestService
      .send(
        'PUT',
        `${endpoints.organization}/${this.storageService.user?.organizationId}`,
        {
          body: {
            isLocalizationOptIn: value,
            isLocalizationOptInSubmitted: true,
            optUserId: this.storageService.user?.id,
          },
          headers: {
            authorization: this.storageService.authorizationToken,
          },
        }
      )
      .then((response) => {
        let data = this.storageService.user;
        data.organizations.isLocalizationOptIn = value;
        data.organizations.isLocalizationOptInSubmitted = true;
        data.organizations.optUserId = this.storageService.user?.id;
        this.storageService.user = data;
        this.loaderService.hideLoader();

        this.featureService.isLocalizationOptIn = value;

        this.notificationService.showNotification(
          'success',
          `You've opted ${value ? 'in' : 'out'} for dynamic translate`
        );

        if (value) {
          this.router.navigate(['/plan/staff']);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  init() {
    this.currentLanguage = {
      img: '../../../../assets/images/brand/english_flag.png',
      code: 'en',
    };
    this.loggedInUser = this.storageService.user;

    if (this.loggedInUser && this.loggedInUser?.id) {
      this.loggedInUser = this.storageService.user;
      if (this.loggedInUser?.profilePic)
        this.profileimagespic =
          environment.serverUrl +
          '/containers/tspturf-expenses/download/' +
          this.loggedInUser?.profilePic;
      else this.profileimagespic = '../../../../assets/images/man.png';
      this.profilePicRotate = this.loggedInUser?.profilePicRotate;
    } else {
      this.signout();
    }

    setTimeout(() => {
      if (this.storageService?.user?.id) this.getFacilityList();
    }, 2000);

    if (this.storageService.currentLanguage) {
      this.changeLanguage(this.storageService.currentLanguage);
    } else {
      this.translate.use('en');
    }
    /**
     * This socket will trigger the facility user's when facility changes
     */
    this.observable = this.globalSocketService
      .refreshFacilityChange()
      .subscribe((data: any) => {
        if (
          data?.currentFacilityId ===
            this.storageService?.user?.currentFacilityId &&
          data?.id === this.storageService?.user?.id
        ) {
          window.location.reload();
        }
      });
  }

  ngOnInit(): void {
    if (this.storageService.user) {
      this.init();
    }
  }

  ngOnDestroy(): void {
    // Added for reload requires
    if (this.observable) this.observable.unsubscribe();
  }

  gotoEditProfile() {
    this.router.navigateByUrl(
      `/${PageRoutes.shortUrls.userProfile.editProfile}`
    );
  }

  gotoChangePassword() {
    this.router.navigateByUrl(
      `/${PageRoutes.shortUrls.userProfile.changePassword}`
    );
  }

  getFacilityList() {
    let query: any = {
      where: {
        organizationId: this.storageService.user.organizationId,
      },
    };
    let temp = this.storageService.user?.assignedFacilities
      .filter((o) => o.facilityId > 0)
      .map((x) => x.facilityId);

    query = {
      where: {
        id: { inq: temp },
      },
      fields: [
        'id',
        'name',
        'timeZone',
        'lat',
        'lng',
        'mapType',
        'currency',
        'unitOfMeasure',
        'organizationId',
      ],
    };

    query = encodeURI(JSON.stringify(query));
    this.requestService
      .send('GET', `${endpoints.facilities}?filter=${query}`, {
        headers: this.token,
      })
      .then((res: any) => {
        this.facilityList = res;
        localStorage.setItem('facilityList', JSON.stringify(this.facilityList));

        this.currentFacility = this.facilityList?.find(
          (i) => i.id == this.storageService.user.currentFacilityId
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }


  private async deleteAccessToken() {
    const api =`${endpoints.users}${endpoints.deleteAccessToken}?access_token=${this.token.authorization}`;
    return await this.requestService
      .send<any>(
        'DELETE',
        api,
        { 
          headers: this.token 
        }
      )
  }

  async signout() {
    
    this.deleteAccessToken().then(result => {
      
      this.router.navigateByUrl(
        `/${PageRoutes.shortUrls.auth.short}/${PageRoutes.shortUrls.auth.signin}`
      );
      this.storageService.clearStorage();

      // This function is clearing all cookies to run helpwise with correct userdata.
      function deleteAllCookies() {
        const cookies = document.cookie.split(';');

        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i];
          const eqPos = cookie.indexOf('=');
          const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
          document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
        }
      }
      deleteAllCookies();

      setTimeout(() => {
        window.location.reload();
      }, 1000);

    });
  }

  changeLanguage(key) {
    this.translate.use(key.toLowerCase());

    if (key == 'en') {
      this.currentLanguage = {
        img: '../../../../assets/images/brand/english_flag.png',
        code: 'en',
      };
      this.storageService.user.currentLanguage = this.currentLanguage;
    } else if (key == 'it') {
      this.currentLanguage = {
        img: '../../../../assets/images/brand/Italian.png',
        code: 'it',
      };
      this.storageService.user.currentLanguage = this.currentLanguage;
    } else if (key == 'sp') {
      this.currentLanguage = {
        img: '../../../../assets/images/brand/Spanish_flag.png',
        code: 'sp',
      };
      this.storageService.user.currentLanguage = this.currentLanguage;
    }
    this.translate.use(key);

    this.storageService.currentLanguage = key;
  }

  chnageCurrentFacility(id) {
    this.requestService
      .send<any>(
        'POST',
        `${endpoints.facilities}/setCurrentFacility?id=${id}`,
        { headers: this.token }
      )
      .then((res) => {
        const user = this.storageService.user;
        user.currentFacilityId = id;
        this.storageService.user = user;
        this.changeRoute(); // TAW-11077
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  changeRoute() {
    if (
      this.router?.url?.includes(
        PageRoutes?.shortUrls?.execute?.timeAssistantPath
      )
    ) {
      if (PageRoutes?.shortUrls?.execute?.timeAssistantHome)
        this.location.replaceState(
          PageRoutes?.shortUrls?.execute?.timeAssistantHome
        );
    }
  }
}
