import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}




// Check if Angular was loaded
if (
  typeof platformBrowserDynamic === 'undefined' ||
  typeof AppModule === 'undefined' 
) {
  // Handle the failure to load Angular
  // Display an error message, clear the localstorage and redirect to login screen
  console.error('Angular was not loaded');
  localStorage.clear();
  setTimeout(() => {
    window.location.reload();
  }, 2000);
} else {
  // Angular was successfully loaded
  // Proceed with bootstrapping the application
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((error) => {
      throw new Error(error);
    });
}


