import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GlobalLayoutComponent } from './components/layouts/global/global-layout/global-layout.component';
import { PageRoutes } from './constants/route';
import { PermissionGuard } from './guards/permission.guard';
import { ChangePasswordComponent } from './pages/user-profile/change-password/change-password.component';
import { EditProfileComponent } from './pages/user-profile/edit-profile/edit-profile.component';

const routes: Routes = [
  {
    path: PageRoutes.shortUrls.auth.short,
    loadChildren: () =>
      import('./pages/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: PageRoutes.shortUrls.onboarding.short,
    loadChildren: () =>
      import('./pages/on-boarding/on-boarding.module').then(
        (m) => m.OnBoardingModule
      ),
  },
  {
    path: PageRoutes.shortUrls.execute.whiteboard,
    canActivateChild: [PermissionGuard],
    loadChildren: () =>
      import('./pages/execute/whiteboard/whiteboard-new.module').then(
        (m) => m.WhiteboardModule
      ),
  },
 

  {
    path: PageRoutes.shortUrls.beacon.short,
    loadChildren: () =>
      import('./pages/beacon/beacon.module').then((m) => m.BeaconModule),
      data: {
        headerBarMainText: 'Labor Management',
        headerBarSubMenu: 'Beacon',
        sub_menu: 'beaconMap',
        filter_menu: [],
        main_module_name: 'LabourManagementModule',
        main_label_children: 'LM_beacon',
        state:'view',
        routing:'turf/labourManagement/beacon',
        id: 'LM_CMI_beacon'
      },
  },

  {
    path: '',
    component: GlobalLayoutComponent,
    children: [
      {
        path: PageRoutes.shortUrls.plan.short,
        canActivateChild: [PermissionGuard],
        loadChildren: () =>
          import('./pages/plan/plan.module').then((m) => m.PlanModule),
      },
      {
        path: PageRoutes.shortUrls.execute.short,
        canActivateChild: [PermissionGuard],
        loadChildren: () =>
          import('./pages/execute/execute.module').then((m) => m.ExecuteModule),
      },

      {
        path: 'time-clock',
        canActivateChild: [PermissionGuard],
        loadChildren: () =>
          import('./pages/execute/time-assist/time-assist.module').then((m) => m.TimeAssistModule),
      },
      {
        path: PageRoutes.shortUrls.reports.short,
        canActivateChild: [PermissionGuard],
        loadChildren: () =>
          import('./pages/report/report.module').then((m) => m.ReportModule),
      },
      {
        path: PageRoutes.shortUrls.admin.short,
        canActivateChild: [PermissionGuard],
        loadChildren: () =>
          import('./pages/admin/admin.module').then((m) => m.AdminModule),
      },

      {
        path: PageRoutes.shortUrls.ourapps,
        loadChildren: () =>
          import('./pages/our-apps/our-apps.module').then((m) => m.OurAppsModule),
      },
      {
        path: PageRoutes.shortUrls.userProfile.editProfile,
        component: EditProfileComponent
        },
        {
          path: PageRoutes.shortUrls.userProfile.changePassword,
          component: ChangePasswordComponent
          },
    ],

  },

  {
    path: 'maplocation',
    loadChildren: () => import('./pages/plan/maplocation/maplocation.module').then(m => m.MaplocationModule),
    // canActivate: [AuthGuardService],
    data: { headerBarMainText: 'Facility Management', headerBarSubMenu: 'Map Location',  sub_menu: 'mapLocation', filter_menu: [] },
  },
  {
    path: 'maplocation/:userId/:facilityId/:token/:host',
    loadChildren: () => import('./pages/plan/maplocation/maplocation.module').then(m => m.MaplocationModule),
    // canActivate: [AuthGuardService],
    data: { headerBarMainText: 'Facility Management', headerBarSubMenu: 'Map Location',  sub_menu: 'mapLocation', filter_menu: [] },
  },
  { path: '**', redirectTo: PageRoutes.shortUrls.auth.short },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
