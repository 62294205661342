

<router-outlet></router-outlet>
<app-confirm></app-confirm>
<app-quick-assigned-facility></app-quick-assigned-facility>
<app-permission-denied></app-permission-denied>
<app-loader></app-loader>
<notifier-container></notifier-container>



<ng-template #template>
  <div>

    <div class="modal-body text-center">
      <p>{{'Do you want to opt in dynamic translate?' }}?</p>
    </div>
    <div class="modal-footer text-center pt-2 pb-2" style="justify-content: center;">
      <button matRipple type="button" class="btn btn-primary" (click)="hasOptedIn(true)" style="margin-right:12px;">{{'Yes' |
        translate }}</button>
      <button matRipple type="button" class="btn btn-default" (click)="hasOptedIn(false)">{{'No' | translate }}</button>
    </div>
  </div>
</ng-template>






