import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/services/app.service';
import { StorageService } from 'src/app/services/storage.serviceV2';
import { TurfService } from 'src/app/services/turf.service';
import {Location} from '@angular/common'; 
import { isReportRoute } from 'src/app/services/api/common-helper.service';
const SCRIPT_PATH = 'https://cdn.loopedin.io/js/sidebar.min.js?v=0.1';

@Component({
  selector: 'app-global-layout',
  templateUrl: './global-layout.component.html',
  styleUrls: ['./global-layout.component.scss'],
})
export class GlobalLayoutComponent implements OnInit {
  myScriptElement;
  maxTabletWidth = 1024;
  minTabletWidth = 760;
  hideSidebar = isReportRoute();
  
  constructor(
    public appService: AppService,
    private router: Router,
    private storageService: StorageService,
    private TurfService: TurfService, 
    private location: Location,
  ) {
    
    // Running script for loopedIn what's new button
    this.myScriptElement = document.createElement("script");
    this.myScriptElement.src = SCRIPT_PATH;
    document.body.appendChild(this.myScriptElement);
  }

  get isTabletView() {
    return window.innerWidth < 1024;
  }

  hideHeader = isReportRoute();
  ngOnInit(): void {
    const currentPageUrl = this.router.url;
    if (currentPageUrl == '/') {
      const $user = this.storageService.user;
      let homepage = $user?.homepage;
      let selectedRoute =  $user?.homepage;
      if (
        this.TurfService.verifyRoute(homepage) && 
        this.TurfService.isAuthorized(homepage, 'view')
        ) {
        // this.router.navigate([homepage]);
      } else {
        // this.router.navigate(['/edit-profile']);
        selectedRoute = '/edit-profile'
      }
    
      this.router.navigate([selectedRoute]);

    } else {
      this.hideSidebar = isReportRoute();
      this.router.navigate([currentPageUrl]);
    }
  }
}
