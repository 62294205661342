<!-- Sidebar  -->
<nav
  class="sidebar content-sidebar navbar align-items-start pl-0 pt-0 pr-0 pb-2"
  id="sidebar"
  [ngClass]="{ 'sidebar-small': !sidebarOpen }"
  (mouseleave)="!sidebarOpen && (navCollapse = '')"
>
  <!-- <div class="sidebar-header">
        <h3>Bootstrap Sidebar</h3>
        <strong>BS</strong>
    </div> -->

  <ul
    id="select_itmes_1"
    class="nav list-unstyled flex-md-column flex-row navbar-nav plan-navigation w-100 justify-content-between"
  >
    <li
      class="nav-item active"
      (mouseenter)="
        !sidebarOpen && (navCollapse == 'plan' ? '' : (navCollapse = 'plan'))
      "
    >
      <!-- routerLink="plan/managed-areas" -->
      <a
        class="nav-dropdown dropdown-toggle"
        (click)="checkModulePermission(coreModules.plan.name)"
      >
        <em class="icon-wallet" aria-hidden="false"></em>
        <span>{{ 'Plan' | translate }}</span>
      </a>
      <ul
        class="nav-collapse collapse list-unstyled"
        id="pagePlan"
        [isAnimated]="sidebarOpen"
        [collapse]="navCollapse != 'plan'"
      >
        <li
          class="nav-item nav-subitem"
          [ngClass]="{ current: router.url == '/plan/managed-areas' }"
          (click)="navCollapseFn()"
        >
          <a class="nav-link" routerLink="plan/managed-areas">
            {{ 'Areas' | translate }}</a
          >
        </li>
        <li
          class="nav-item nav-subitem"
          [ngClass]="{ current: router.url == '/plan/staff' }"
          (click)="navCollapseFn()"
        >
          <a class="nav-link" routerLink="plan/staff">{{
            'Staff' | translate
          }}</a>
        </li>
        <li
          class="nav-item nav-subitem"
          [ngClass]="{ current: router.url == '/plan/tasks' }"
          (click)="navCollapseFn()"
        >
          <a class="nav-link" routerLink="plan/tasks">{{
            'Tasks' | translate
          }}</a>
        </li>
        <li
          class="nav-item nav-subitem"
          [ngClass]="{ current: router.url == '/plan/products' }"
          (click)="navCollapseFn()"
        >
          <a class="nav-link" routerLink="plan/products">{{
            'Products' | translate
          }}</a>
        </li>
        <li
          class="nav-item nav-subitem"
          [ngClass]="{ current: router.url == '/plan/application-planner' }"
          (click)="navCollapseFn()"
        >
          <a class="nav-link" routerLink="plan/application-planner">{{
            'Applications' | translate
          }}</a>
        </li>
        <li
          class="nav-item nav-subitem"
          [ngClass]="{ current: router.url == '/plan/parts' }"
          (click)="navCollapseFn()"
        >
          <a class="nav-link" routerLink="plan/parts">{{
            'Parts' | translate
          }}</a>
        </li>
        <li
          class="nav-item nav-subitem"
          [ngClass]="{ current: router.url == '/plan/equipment' }"
          (click)="navCollapseFn()"
        >
          <a class="nav-link" routerLink="plan/equipment">{{
            'Equipment' | translate
          }}</a>
        </li>
        <li
          class="nav-item nav-subitem"
          [ngClass]="{ current: router.url == '/plan/budget-categories' }"
          (click)="navCollapseFn()"
        >
          <a class="nav-link" routerLink="plan/budget-categories">{{
            'BudgetCategories' | translate
          }}</a>
        </li>
        <li
          class="nav-item nav-subitem"
          [ngClass]="{ current: router.url == '/plan/budgets' }"
          (click)="navCollapseFn()"
        >
          <a class="nav-link" routerLink="plan/budgets">{{
            'Budgets' | translate
          }}</a>
        </li>
        <li
          class="nav-item nav-subitem"
          [ngClass]="{ current: router.url == '/plan/forms' }"
          (click)="navCollapseFn()"
        >
          <a class="nav-link" routerLink="plan/forms">{{
            'Forms' | translate
          }}</a>
        </li>
      </ul>
    </li>
    <li class="nav-item">
      <!-- routerLink="execute/schedule-assistant" -->
      <a
        class="nav-dropdown dropdown-toggle"
        (click)="checkModulePermission(coreModules.execute.name)"
        (mouseenter)="
          !sidebarOpen &&
            (navCollapse == 'execute' ? '' : (navCollapse = 'execute'))
        "
      >
        <em ng-hide="inSubmenu" class="icon-people" aria-hidden="false"></em>
        <span>{{ 'Execute' | translate }}</span>
      </a>
      <ul
        class="nav-collapse collapse list-unstyled"
        id="pageExecute"
        [isAnimated]="sidebarOpen"
        [collapse]="navCollapse != 'execute'"
      >
        <li
          class="nav-item nav-subitem"
          [ngClass]="{ current: router.url == '/execute/schedule-assistant' }"
          (click)="navCollapseFn()"
        >
          <a class="nav-link" routerLink="execute/schedule-assistant">{{
            'ScheduleAssistant' | translate
          }}</a>
        </li>
        <li
          class="nav-item nav-subitem"
          [ngClass]="{ current: router.url == '/execute/equipment-assistant' }"
          (click)="navCollapseFn()"
        >
          <a class="nav-link" routerLink="execute/equipment-assistant">{{
            'EquipmentAssistant' | translate
          }}</a>
        </li>
        <li
          class="nav-item nav-subitem"
          [ngClass]="{ current: router.url == '/execute/expenses-assistant' }"
          (click)="navCollapseFn()"
        >
          <a class="nav-link" routerLink="execute/expenses-assistant">{{
            'ExpenseAssistant' | translate
          }}</a>
        </li>
        <li
          class="nav-item nav-subitem"
          [ngClass]="{ current: router.url == '/time-clock/status' }"
          (click)="navCollapseFn()"
        >
          <a class="nav-link" routerLink="time-clock/status">Time Assistant</a>
        </li>
        <li
          class="nav-item nav-subitem"
          [ngClass]="{ current: router.url == '/execute/forms-assistant' }"
          (click)="navCollapseFn()"
        >
          <a class="nav-link" routerLink="execute/forms-assistant"
            >Form Assistant</a
          >
        </li>
        <li
          class="nav-item nav-subitem"
          [ngClass]="{ current: router.url == '/execute/whiteboard' }"
          (click)="navCollapseFn()"
        >
          <a class="nav-link" href="/#/execute/whiteboard" target="_blank">
            {{ 'Whiteboard' | translate }}
          </a>
        </li>
      </ul>
    </li>
    <li class="nav-item" (click)="checkModulePermission(coreModules.report.name)">
      <!-- routerLink="reports/staff" -->
      <a
        class="nav-dropdown dropdown-toggle"
        [ngClass]="{ 'active-main-module': router.url == '/reports/new-report' }"
        
        (mouseenter)="
          !sidebarOpen &&
            (navCollapse == 'report' ? '' : (navCollapse = 'report'))
        "
      >
        <em class="icon-docs" aria-hidden="false"></em>
        <span>{{ 'Report' | translate }}</span>
      </a>
      
    
    </li>
    <li class="nav-item">
      <a
        [routerLink]="isRootUser ? '/admin/manufacturers' : router.url"
        class="nav-dropdown dropdown-toggle"
        (click)="checkModulePermission(coreModules.admin.name)"
        (mouseenter)="
          !sidebarOpen &&
            (navCollapse == 'admin' ? '' : (navCollapse = 'admin'))
        "
      >
        <em class="icon-user" aria-hidden="false"></em>
        <span>{{ 'Admin' | translate }} </span>
      </a>
      <ul
        class="nav-collapse collapse list-unstyled admin-menu-section"
        id="pageAdmin"
        [isAnimated]="sidebarOpen"
        [collapse]="navCollapse != 'admin'"
      >


        

        <li
        class="nav-item nav-subitem"
        [ngClass]="{ current: router.url == '/admin/app-config' }"
        (click)="navCollapseFn()"
        *ngIf="isRootUser"
      >
        <a class="nav-link" routerLink="admin/app-config">{{
          'AppConfig' | translate
        }}</a>
      </li>



        <li
          class="nav-item nav-subitem"
          [ngClass]="{ current: router.url == '/admin/manufacturers' }"
          (click)="navCollapseFn()"
          *ngIf="isRootUser"
        >
          <a class="nav-link" routerLink="admin/manufacturers">{{
            'Manufacturers' | translate
          }}</a>
        </li>
        
        <li
          class="nav-item nav-subitem"
          [ngClass]="{ current: router.url == '/admin/admin-management' }"
          (click)="navCollapseFn()"
          *ngIf="isRootUser"
        >
          <a class="nav-link" routerLink="admin/admin-management">{{
            'AdminManagement' | translate
          }}</a>
        </li>
        <li 
          class="nav-item nav-subitem" 
          *ngIf="isRootUser"
          (click)="navCollapseFn()"
          >
          <a class="nav-link" routerLink="admin/packages">{{
            'Packages' | translate
          }}</a>
        </li>
        <li 
          class="nav-item nav-subitem"
          (click)="navCollapseFn()"
        >
          <a class="nav-link" routerLink="admin/roles">{{
            'UserRoles' | translate
          }}</a>
        </li>
        <li 
          class="nav-item nav-subitem"
          (click)="navCollapseFn()"
        >
          <a class="nav-link" routerLink="admin/user-management">{{
            'UserManagement' | translate
          }}</a>
        </li>
        <li class="nav-item nav-subitem"
          (click)="navCollapseFn()"
        >
          <a class="nav-link" routerLink="admin/app-setups">{{
            'AppSetup' | translate
          }}</a>
        </li>
        <li class="nav-item nav-subitem"
          (click)="navCollapseFn()"
        >
          <a class="nav-link" routerLink="admin/facility">{{
            'Facility Info' | translate
          }}</a>
        </li>
        <!-- <li class="nav-item nav-subitem">
          <a class="nav-link" routerLink="admin/plan-products">{{
            'Plan Products' | translate
          }}</a>
        </li> -->
        <li
          class="nav-item nav-subitem"
          [ngClass]="{ current: router.url == '/admin/billing' }"
          (click)="navCollapseFn()"
          *ngIf="!isRootUser"
        >
          <a class="nav-link" routerLink="admin/billing">{{
            'Billing' | translate
          }}</a>
        </li>
        <!-- <li class="nav-item nav-subitem" *ngIf="isRootUser"><a class="nav-link" routerLink="admin/invite">{{'Invite' | translate }}</a></li> -->
        <li 
          class="nav-item nav-subitem" 
          (click)="navCollapseFn()"
          *ngIf="isRootUser">
          <a class="nav-link" routerLink="admin/plan-area-template">{{
            'Plan Area Template' | translate
          }}</a>
        </li>
        <li 
          class="nav-item nav-subitem" 
          (click)="navCollapseFn()"
          *ngIf="isRootUser"
        >
          <a class="nav-link" routerLink="admin/task-templates">{{
            'TaskTemplates' | translate
          }}</a>
        </li>
        <li class="nav-item nav-subitem"
          (click)="navCollapseFn()" 
          *ngIf="isRootUser"
        >
          <a class="nav-link" routerLink="admin/documents">{{
            'Documents' | translate
          }}</a>
        </li>
        <li 
          class="nav-item nav-subitem" 
          *ngIf="isRootUser"
          (click)="navCollapseFn()"
        >
          <a class="nav-link" routerLink="admin/chemicals">{{
            'Chemicals' | translate
          }}</a>
        </li>
        <li 
          class="nav-item nav-subitem" 
          *ngIf="isRootUser"
          (click)="navCollapseFn()"
        >
          <a class="nav-link" routerLink="admin/equipmenttypes">{{
            'EquipmentTypes' | translate
          }}</a>
        </li>
        <!-- <li class="nav-item nav-subitem" *ngIf="isRootUser"><a class="nav-link" routerLink="admin/wireless-hour-meters"> {{'WirelessHourMeters' | translate }} </a></li> -->
        <li 
          class="nav-item nav-subitem" 
          *ngIf="isRootUser"
          (click)="navCollapseFn()"
        >
          <a class="nav-link" routerLink="admin/bulk-upload">{{
            'BulkUpload' | translate
          }}</a>
        </li>
        <!-- <li class="nav-item nav-subitem" *ngIf="!isRootUser"><a class="nav-link" routerLink="user/billing">{{'Billing' | translate }}</a></li>  -->
      </ul>
    </li>
  </ul>

  <ul
    id="select_itmes_2"
    class="nav list-unstyled flex-md-column flex-row navbar-nav w-100 justify-content-between leftsidebar_nav bottomLinks mb-0"
  >
    <li class="nav-item">
      <a class="nav-link" href="/#/turf/beacon/map" target="_blank">
        <em
          class="home123_icn fa fa-bullseye"
          tooltip-placement="right"
          [attr.tooltip]="'Beacon' | translate"
        ></em>
        <span>{{ 'Beacon' | translate }}</span>
      </a>
      <!-- <a class="nav-link" (click)="beaconTarget()">
    <em class="home123_icn fa fa-bullseye"
    tooltip-placement="right"
     [attr.tooltip]="'Beacon' | translate"
                  ></em> 
    <span>{{'Beacon' | translate }}</span>
  </a> -->
    </li>

    <li id="facebook" (click)="gotoUniversity()">
      <a target="_blank">
        <em
          tooltip-placement="right"
          [attr.tooltip]="'University' | translate"
          ng-hide="inSubmenu"
          class="facebook_icn fa fa-university"
          style="font-size: 14px"
          aria-hidden="false"
        ></em>
        <span>{{ 'University' | translate }}</span>
      </a>
    </li>
    <li id="apps">
      <a href="#/ourapps" target="_blank">
        <em
          ng-hide="inSubmenu"
          class="apple_icn fa fa-th-large"
          tooltip-placement="right"
          [attr.tooltip]="'Apps' | translate"
          aria-hidden="false"
        ></em>
        <span>{{ 'Apps' | translate }}</span>
      </a>
    </li>
    <li id="started" class="home_icn">
      <a href="javascript:void(0)">
        <em
          class="home123_icn fa fa-home"
          tooltip-placement="right"
          [attr.tooltip]="'MyHomePage' | translate"
        >
        </em>

        <select
          id="home-select"
          class=""
          [(ngModel)]="selected_item"
          (change)="setHomePage()"
          aria-invalid="false"
        >

        <option *ngFor = "let list of homepageList" [ngValue]="list.url" [innerText]="list?.name | translate">
          {{list?.name | translate}}

        </option>
    
        </select>
      </a>
    </li>
  </ul>
</nav>
