import { Component, Inject, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-global-error',
  // template: `
  //   <div class="popup-container">
  //     <p> {{ dataFromParent }}</p>
  //     <button class="btn btn-primary" (click)="onClose()">Click to fix</button>
  //   </div>
  // `,
  templateUrl: './global-error-popup.html',
  styleUrls: ['./global-error-popup.scss'],
})
export class GlobalErrorComponent {
  dataFromParent: any;

  constructor(
    private ngZone: NgZone,
    private dialogRef: MatDialogRef<GlobalErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any // Inject the data from the dialog configuration
  ) {
    this.dataFromParent = data; // Assign the data to a local variable
  }

  closePopup() {
    this.ngZone.run(() => {
      this.dialogRef.close();
    });
  }
}
