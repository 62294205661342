import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { endpoints } from 'src/app/constants/endpoints';
import { isReportRoute } from 'src/app/services/api/common-helper.service';
import { AppService } from 'src/app/services/app.service';
import { RequestService } from 'src/app/services/request.service';
import { StorageService } from 'src/app/services/storage.serviceV2';
import { getCurrentYear } from 'src/app/shared/dates/index'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  year = getCurrentYear();
  hideSidebar= isReportRoute();
  token:any;
  
  footerText = `${this.year} - Turf Assistant | Version: 12.3.0`;
  constructor(public appService: AppService,
    private requestService: RequestService,
    private storageService: StorageService,
    private router: Router,
  ) {
      this.token = {
        authorization: this.storageService.authorizationToken,
      };
    this.hideSidebar = isReportRoute();
    
    }

  ngOnInit(): void {
    this.getVersion()
  }

  getVersion() {

    let query: any = {
      where:{
        "status": "live",
        platform: "web"
      },
      limit:1
    };

    this.requestService
      .send('GET', `${endpoints.appConfig}?filter=${JSON.stringify(query)}`, {
        headers: this.token,
      })
      .then((res) => {
        if(res?.length > 0){
          this.footerText = `${this.year} - Turf Assistant | Version: ${res[0].version}`;
        } else {
          this.footerText = `${this.year} - Turf Assistant | Version: 12.1.1 (3)`;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
}
  