import { WhiteboardService } from 'src/app/services/beacon/whiteboard.service';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { LoaderService } from '../services/common-helper-service/loder.service';
import { RequestService } from '../services/request.service';
import { StorageService } from '../services/storage.serviceV2';
import { TurfService } from '../services/turf.service';

import { endpoints } from '../constants/endpoints';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { PageRoutes } from '../constants/route';
// import { HubspotService } from '../services/hubspot.service';
import { ImpersonateService } from '../services/impersonate.service';
import * as LogRocket from 'logrocket';
import {
  getCurrentDateTimeStartISO,
  luxonDateTime,
} from 'src/app/shared/dates/index';

import {
  getDefaultZone,
  initializeTimeZoneFromLocalStorage,
} from 'src/app/shared/dates';
import { CommonHelperService } from '../services/api/common-helper.service';
import { notificationService } from '../services/notification.service';
import { environment } from 'src/environments/environment';

// const chmln = require('@chamaeleonidae/chmln');

import * as chmln from '@chamaeleonidae/chmln';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FeatureService } from '../services/feature-service/feature.service';
import { AppService } from '../services/app.service';
import { GlobalSocketService } from 'src/app/services/api/socket.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
})
export class PagesComponent implements OnInit, OnDestroy {
  headers: any = {};

  isSessionAdded = false;

  hideOnWhiteboardRoutes = false;
  clockOutObservable: any;
  timeIntervalId: any;

  @ViewChild('template') template;
  modalRef?: BsModalRef;

  constructor(
    public loaderService: LoaderService,
    translate: TranslateService,
    private TurfService: TurfService,
    private requestService: RequestService,
    public storageService: StorageService,
    private router: Router,
    // private hubspotService: HubspotService,
    private impersonateService: ImpersonateService,
    private commonHelperService: CommonHelperService,
    private notificationService: notificationService,
    private modalService: BsModalService,
    private featureService: FeatureService,
    private whiteboardService: WhiteboardService,
    private appService: AppService,
    private globalSocketService: GlobalSocketService,
    private toastrService: ToastrService,

  ) {
    if (this.storageService.currentLanguage) {
      translate.use(this.storageService.currentLanguage);
    } else {
      translate.use('en');
    }
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        var fields = event.url.split('?');

        // This will hide whatsnew link from the header for all routes and subroutes of whiteboard
        if (
          event.url.indexOf('/execute/whiteboard') == 0 ||
          event.url.indexOf('/maplocation') == 0 ||
          event.url.indexOf('/turf/beacon') == 0
        ) {
          this.hideOnWhiteboardRoutes = true;
        } else {
          this.hideOnWhiteboardRoutes = false;
        }

        if (
          (event && event.url == '/auth/signup') ||
          event.url == '/onboarding/terms-and-conditions' ||
          event.url == '/auth/forgot-password' ||
          fields[0] == '/auth/password/reset'
        ) {
          this.loadMissiveChatScript()
          return;
        }
        
        if (
          !this.storageService.user ||
          this.storageService.FACILITIES_LIST?.length === 0
        ) {
          // this.hubspotService.logout();
          setTimeout(() => {
            // console.clear();
            this.loaderService.hideLoader();
            this.router.navigateByUrl(
              `/${PageRoutes.shortUrls.auth.short}/${PageRoutes.shortUrls.auth.signin}`
            );
            this.loadMissiveChatScript();
          }, 500);
        }
      }
    });
  }

  ngOnInit() {
    this.componentInit();

    // Check Pending invoice on page login
    if (
      this.storageService?.user &&
      this.storageService?.user?.userType?.toLowerCase() !== 'root'
    ) {
      this.appService.checkPendingInvoice(
        this.storageService?.user?.organizations?.subscription?.zohoSubscriptionId,
        {
          authorization: this.storageService.authorizationToken,
        }
      );

      // Initial load
      this.loadMissiveChatScript();
    }

    this.globalSocketService.copy_planner_completed_socket().subscribe((data:any) => {
      if (data.status) {
        this.toastrService.success(
          `Total ${data.taskCount} task(s) copied`,
          'Success'
        );
      } else {
        this.toastrService.error(
          `Error occured while copying application planner tasks`,
          'Error'
        );
      }
    });
    
  }


  loadMissiveChatScript() {
    if (
      this.storageService?.user &&
      this.storageService?.user?.userType?.toLowerCase() !== 'root'
    ) {
      const existingScript = document.querySelector(`script[src="https://webchat.missiveapp.com/28672e3b-2513-489a-8bc0-1989df392686/missive.js"]`);
      if (existingScript) {
        existingScript.remove(); // Remove existing script
        delete (window as any).MissiveChatConfig; // Clear previous config
      }
  
      (window as any).MissiveChatConfig = {
        id: environment.massiveAppKey,
        user: {
          id: this.storageService?.user?.id,
          name: `${this.storageService?.user?.firstname} ${this.storageService?.user?.lastname}`,
          email: this.storageService?.user?.email,
          digest: this.storageService?.missiveAppDigest
        },
      };
  
      const script = document.createElement('script');
      script.async = true;
      script.src = `https://webchat.missiveapp.com/${(window as any).MissiveChatConfig.id}/missive.js`;
      document.head.appendChild(script);
    }
  }

  




  componentInit() {
    /**
     * This socket will trigger the organization's users after auto clockout
     */

    this.clockOutObservable = this.whiteboardService
      ?.autoClockoutCompleted()
      ?.subscribe(() => {
        window.location.reload();
        // console.log('clock out socket triggered')
      });

    if (this.storageService.authorizationToken && this.storageService.user) {
      initializeTimeZoneFromLocalStorage;

      if (environment.logRocket) {
        LogRocket.init('efwfxl/turf-assistant');
      }

      this.headers = {
        authorization: this.storageService.authorizationToken,
      };

      initializeTimeZoneFromLocalStorage();
      this.initLogRocket();

      this.getUser();
      this.initChamelon();
    }
  }

  openModal(template: TemplateRef<any>) {
    if (
      this.storageService.user?.id &&
      !this.storageService.user?.organizations?.isLocalizationOptInSubmitted
    ) {
      this.modalRef = this.modalService.show(template, {
        class: ' modal-dialog-centered modal-sm',
        ignoreBackdropClick: true,
      });
    }
  }

  hasOptedIn(value?) {
    alert(value);
    this.updateOptIn(value);
    this.modalRef.hide();
  }

  async updateOptIn(value) {
    try {
      const response = await this.requestService.send(
        'PUT',
        `${endpoints.organization}/${this.storageService.user?.organizationId}`,
        {
          body: {
            isLocalizationOptIn: value,
            isLocalizationOptInSubmitted: true,
            optUserId: this.storageService.user?.id,
          },
          headers: {
            authorization: this.storageService.authorizationToken,
          },
        }
      );

      const data = this.storageService.user;
      data.organizations.isLocalizationOptIn = value;
      data.organizations.isLocalizationOptInSubmitted = true;
      data.organizations.optUserId = this.storageService.user?.id;
      this.storageService.user = data;

      this.loaderService.hideLoader();
      this.modalRef.hide();

      this.featureService.isLocalizationOptIn = value;

      this.notificationService.showNotification(
        'success',
        `You've opted ${value ? 'in' : 'out'} for dynamic translate`
      );

      if (value) {
        this.router.navigate(['/plan/staff']);
      }
    } catch (error) {
      this.errorHandler(error);
    }
  }

  redirect() {
    this.router.navigate(['/edit-profile']);
  }

  ngOnDestroy(): void {
    try {
      // Potential error-prone code here
      // this.hubspotService.remove();
    } catch (error) {
      console.error('An error occurred:', error);
      // Perform error handling actions like logging or redirecting to an error page
      // Alternatively, you can use a centralized error handling service to handle errors
    }
  }

  initChamelon() {
    /* chameleon.io NPM script */
    chmln.init(
      'S4Z9gz0Gj64Owa6dHf1bk3tikDj3TN6GHmGEszfBs1JNN7-1Pi3c3-E9ZOwUCb1eYpQ7Cd',
      { fastUrl: 'https://fast.chameleon.io/' }
    );
    chmln.identify(this.storageService.user?.id, {
      // REQUIRED, the unique ID of each user in your database (e.g. 23443 or "690b80e5f433ea81b96c9bf6")
      email: this.storageService?.user?.email, // RECOMMENDED, email is used as the key to map user data for integrations
      name: `${this.storageService?.user?.firstname} ${this.storageService.user?.lastname}`, // RECOMMENDED, name can be used to greet and/or personalize content
    });
  }

  errorHandler(error) {
    this.loaderService.hideLoader();
    this.notificationService.showNotification(
      'error',
      this.commonHelperService.handleError(error)
    );
    this.storageService.clearStorage();
  }

  getUser() {
    try {
      if (this.storageService.user && this.storageService.authorizationToken) {
        let query: any = {
          where: {
            id: this.storageService.user?.id,
          },
          include: [
            {
              relation: 'userAssignedFacilities',
              scope: {
                include: [
                  {
                    relation: 'facility',
                    scope: {
                      fields: [
                        'id',
                        'name',
                        'timeZone',
                        'lat',
                        'lng',
                        'mapType',
                        'currency',
                        'unitOfMeasure',
                        'organizationId',
                      ],
                    },
                  },
                ],
              },
            },
            {
              relation: 'organizations',
              scope: {
                include: {
                  relation: 'subscription',
                  scope: {
                    include: ['package'],
                  },
                },
              },
            },
          ],
        };
        query = encodeURIComponent(JSON.stringify(query));
        this.requestService
          .send('GET', `${endpoints.users}?filter=${query}`, {
            headers: this.headers,
          })
          .then((res: any) => {
            try {
              if (res?.length) {
                let user = res[0];
                user.package = user?.organizations?.subscription?.package;
                user.organizations = user?.organizations;
                user.assignedFacilities = user?.userAssignedFacilities;
                this.storageService.user = user;
                // this.hubspotService?.initV2({
                //   email: this.storageService?.user?.email,
                //   userId: this.storageService?.user?.id,
                // });

                this.findRole(this.storageService.user);
                // console.log('get user', JSON.stringify((window as any)._hsq) + this.storageService.user?.id)
              } else {
                this.errorHandler(res);
              }
            } catch (e) {
              this.errorHandler(e);
            }
          })
          .catch((err) => {
            this.errorHandler(err);
          });
      }
    } catch (e) {
      this.errorHandler(e);
    }
  }

  initLogRocket() {
    if (
      this.storageService?.user?.id &&
      window.location.hostname.indexOf('localhost') != 0
    ) {
      LogRocket.identify(this.storageService?.user.id, {
        name: `${this.storageService?.user.firstname} ${this.storageService?.user.lastname}`,
        email: this.storageService?.user?.email,
        subscriptionType: this.storageService?.user?.package?.name,
      });
    }
  }

  findRole(user?) {
    let facilityIds = this.storageService.user?.assignedFacilities
      .filter((o) => o.facilityId > 0)
      .map((x) => x.facilityId);

    const facilityFilter: any = {
      where: { isActive: true },
      fields: [
        'id',
        'name',
        'timeZone',
        'lat',
        'lng',
        'mapType',
        'currency',
        'unitOfMeasure',
        'organizationId',
      ],
    };
    facilityFilter.where.id = {
      inq: facilityIds,
    };
    this.requestService
      .send<any>(
        'GET',
        `${endpoints.facilities}?filter=${JSON.stringify(facilityFilter)}`,
        { headers: this.headers }
      )
      .then((facilities) => {
        const user = this.storageService.user;
        user.facilities = facilities;
        this.storageService.user = user;
      });

    if (user?.createdBy) {
      const filter = { where: { principalId: user.id }, include: ['role'] };
      const api = `/RoleMappings?filter=${JSON.stringify(filter)}`;
      this.requestService
        .send('GET', api, { headers: this.headers })
        .then((res) => {
          if (res?.length) {
            user.role = res[0].role;
            user.role.permission = JSON.parse(user.role.permission);
            this.storageService.user = user;
            this.checkImpersonateForRootUser();
            this.TurfService.setUser(user);
          }

          // this.findPrevStatus();
        });
    } else {
      const filter = { where: { principalId: user.id }, include: ['role'] };
      const api = `${endpoints.rolePermissions}?filter=${JSON.stringify(filter)}`;
      this.requestService
        .send<any>('GET', api, { headers: this.headers })
        .then((res) => {
          if (!user.role) user.role = {};
          user.role.permission = res.permissions;

          this.storageService.user = user;
          this.checkImpersonateForRootUser();
          this.TurfService.setUser(user);
          // this.findPrevStatus();
        });
    }
  }

  logout() {
    const api = `/users/logout`;
    this.requestService
      .send('GET', api)
      .then(() => {
        this.storageService.clearStorage();
        // this.hubspotService.remove();
        setTimeout(() => {
          window.location.pathname = '/';
          window.location.reload();
        }, 100);
      })
      .catch(function (err) {
        this.storageService.clearStorage();
      });
  }

  private checkImpersonateForRootUser() {
    if (this.storageService.user.userType == 'Root') {
      if (
        this.storageService.user.impersonateUserId &&
        !this.storageService.impersonate
      ) {
        const userId = this.storageService.user.impersonateUserId;
        this.requestService
          .send<any>('GET', `${endpoints.users}/${userId}`, {
            headers: this.headers,
          })
          .then((user) => {
            setInterval(() => {
              user.facilities = this.storageService.allFacilityList;
              if (user.facilities?.length) {
                this.impersonateService.startImpersonate(user);
              }
            }, 500);
          });
      } else if (
        !this.storageService.user.impersonateUserId &&
        this.storageService.impersonate
      ) {
        this.storageService.removeImpersonate;
        setTimeout(() => location.reload(), 300);
      }
    }
  }

  private findPrevStatus() {
    if (this.storageService.user.currentFacilityId) {
      this.getTimeZone();

      let offset = getDefaultZone();

      if (!offset) {
        const timeZone = this.storageService.allFacilityList?.find(
          (i) => i.id == this.storageService?.user?.currentFacilityId
        )?.timeZone;

        offset = timeZone;
      }

      let _dayStart = getCurrentDateTimeStartISO();

      let query = {
        filter: {
          where: {
            facilityId: this.storageService.user?.currentFacilityId,
            clockOutDate: null,
            clockInDate: { lt: encodeURIComponent(_dayStart) },
          },
        },
      };

      // TimeClock.find(query).$promise
      this.requestService
        .send<any>(
          'GET',
          `${endpoints.timeClock}?filter=${JSON.stringify(query.filter)}`,
          { headers: this.headers }
        )
        .then((res) => {
          var prevTimeClockIds = [];
          for (let j = 0; j < res.length; j++) {
            prevTimeClockIds.push(res[j]['id']);
          }
          if (prevTimeClockIds.length > 0) {
            const params = {
              facilityId: this.storageService.user?.currentFacilityId,
              clockInArr: prevTimeClockIds,
              offset: offset,
            };
            this.requestService
              .send<any>(
                'GET',
                `${
                  endpoints.workers
                }/updateWorkerActivity?params=${JSON.stringify(params)}`,
                { headers: this.headers }
              )

              .then(
                (res) => {
                  console.log('updateWorkerActivity', res);
                },
                (err) => {
                  console.log(err);
                }
              );
          }
        });
    }
  }

  private getTimeZone() {
    initializeTimeZoneFromLocalStorage();
    // TODO deprecate below method.
    const facilityList = this.storageService.allFacilityList;
    if (facilityList?.length) {
      const timezone = facilityList.find(
        (i) => i.id == this.storageService?.user?.currentFacilityId
      )?.timeZone;
      if (timezone) {
        this.timeIntervalId = setInterval(function () {
          if (
            luxonDateTime().now().setZone(timezone).toFormat('HH:mm:ss') ===
              '00:00:01' ||
            luxonDateTime().now().setZone(timezone).toFormat('HH:mm:ss') ===
              '00:00:02'
          ) {
            // Reloading the page after 2 min of 12 am
            window.location.reload();
          }
        }, 1000);

        initializeTimeZoneFromLocalStorage;
      } else {
        setTimeout(() => this.getTimeZone(), 1000);
      }
    } else {
      setTimeout(() => this.getTimeZone(), 1000);
    }
  }


}
