import { Component, OnDestroy, OnInit } from '@angular/core';
import { endpoints } from '../../../constants/endpoints';
import { StorageService } from 'src/app/services/storage.serviceV2';
import { RequestService } from 'src/app/services/request.service';
import { Router } from '@angular/router';
import { TurfService } from 'src/app/services/turf.service';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { PermissionDeniedService } from 'src/app/shared/permission-denied/permission-denied.service';

declare var Showcaser: any;

class SidebarModule {
  constructor(
    public name: string,
    public subModules: { url: string; permission: string }[]
  ) {}
}

@Component({
  selector: 'app-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss'],
})
export class SideNavigationComponent implements OnInit, OnDestroy {
  token: any;
  location: any = [];
  Facility: any = [];
  isTutorialShow = false;

  isRootUser = false;

  navCollapse = '';

  sidbarOpenSubscription: Subscription;
  sidebarOpen;

  userData;
  homepageList = [
    { name: 'MyHomePage', url: null },
    { name: 'PlanAreas', url: 'plan/managed-areas' },
    { name: 'Staff', url: 'plan/staff' },
    { name: 'Tasks', url: 'plan/tasks' },
    { name: 'Products', url: 'plan/products' },
    { name: 'Applications', url: 'plan/application-planner' },
    { name: 'Parts', url: 'plan/parts' },
    { name: 'Equipment', url: 'plan/equipment' },
    { name: 'BudgetCategories', url: 'plan/budget-categories' },
    { name: 'Budget', url: 'plan/budgets' },
    { name: 'Forms', url: 'plan/forms' },

    { name: 'ScheduleAssistant', url: 'execute/schedule-assistant' },
    { name: 'EquipmentAssistant', url: 'execute/equipment-assistant' },
    { name: 'ExpensesAssistant', url: 'execute/expenses-assistant' },
    { name: 'TimeAssistant', url: 'time-clock/status' },
    { name: 'FormsAssistant', url: 'execute/forms-assistant' },
  ];

  readonly coreModules = {
    plan: new SidebarModule('plan', [
      { url: '/plan/managed-areas', permission: 'plan/managed-areas' },
      { url: '/plan/staff', permission: 'plan/staff' },
      { url: '/plan/tasks', permission: 'plan/task-grid' },
      { url: '/plan/products', permission: 'plan/products' },
      {
        url: '/plan/application-planner',
        permission: 'plan/application-planner',
      },
      { url: '/plan/parts', permission: 'plan/facilityParts' },
      { url: '/plan/equipment', permission: 'plan/equipment' },
      { url: '/plan/budget-categories', permission: 'plan/budgetCategories' },
      { url: '/plan/budgets', permission: 'plan/budgets' },
      { url: '/plan/forms', permission: 'plan/forms' },
    ]),

    execute: new SidebarModule('execute', [
      {
        url: '/execute/schedule-assistant',
        permission: 'execute/schedule-assistant',
      },
      {
        url: '/execute/equipment-assistant',
        permission: 'execute/equipment-assistant',
      },
      {
        url: '/execute/expenses-assistant',
        permission: 'execute/expenses-assistant',
      },
      { url: '/time-clock/status', permission: 'time-clock/status' },
      {
        url: '/execute/forms-assistant',
        permission: 'execute/forms-assistant',
      },
      { url: '/execute/whiteboard', permission: 'execute/whiteboard' },
    ]),

    report: new SidebarModule('report', [
      { url: '/reports/new-report', permission: 'reports' },
      { url: '/reports/staff', permission: 'reports' },
      { url: '/reports/application', permission: 'reports/chemicals' },
      { url: '/reports/budget', permission: 'reports/budget' },
    ]),

    admin: new SidebarModule('admin', [
      { url: '/admin/roles', permission: 'roles' },
      { url: '/admin/user-management', permission: 'users' },
      { url: '/admin/app-setups', permission: 'application' },
      { url: '/admin/facility', permission: 'facility' },
      { url: '/admin/billing', permission: 'user/billing' },
      
    ]),
  };
  selected_item;

  constructor(
    private requestService: RequestService,
    private storageService: StorageService,
    public router: Router,
    private TurfService: TurfService,
    private appService: AppService,
    private permissionDeniedService: PermissionDeniedService
  ) {
    this.token = {
      authorization: this.storageService.authorizationToken,
    };
  }

  ngOnInit(): void {
    this.userData = this.storageService.user;
    this.selected_item = this.userData?.homepage;

    if (this.storageService.user && !this.storageService.user.isTutorial) {
      setTimeout(() => {
        this.showTutorial();
      }, 1000);
    }

    this.isRootUser = this.storageService.user?.userType == 'Root';

    this.sidbarOpenSubscription = this.appService.sidebarOpen.subscribe(
      (bool) => {
        this.sidebarOpen = bool;
        if (!bool) {
          this.navCollapse = '';
        }
      }
    );
  }

  navCollapseFn() {
    if (window.innerWidth < 1024 && !this.sidebarOpen) {
      this.navCollapse=''
    }
  }

  ngOnDestroy() {
    this.sidbarOpenSubscription.unsubscribe();
  }

  gotoUniversity() {
    /* let urlUniversity =
      'http://university.turfassistant.com/?token=' +
      this.storageService.authorizationToken +
      '&userId=' +
      this.storageService.user.id +
      '&username=' +
      this.storageService.user.firstname; */

    const urlUniversity = `https://help.turfassistant.com`;
    window.open(urlUniversity, '_blank');
  }

  beaconTarget() {
    // It will trigger on open beacon
    this.TurfService.trackEvent(`Beacon open`, {
      details: `Redirect to beacon`,
      event: `Beacon opened successfully`,
      data: {},
    });

    var url = '';
    // url = "http://localhost:4200/beacon/"+$localStorage.user.id+"/"+$localStorage.currentFacilityId+"/"+LoopBackAuth.accessTokenId;
    if (
      window.location.host === 'app.turfassistant.com' ||
      window.location.host === 'staging.turfassistant.com'
    ) {
      url =
        'http://35.155.227.119/#/turf/beacon/map/' +
        this.storageService.user.id +
        '/' +
        this.storageService.user.currentFacilityId +
        '/' +
        this.storageService.authorizationToken +
        '/' +
        window.location.host.split('.')[0];
    } else if (window.location.host == 'qa.turfassistant.com') {
      url =
        'http://100.20.3.139:4200/#/turf/beacon/map/' +
        this.storageService.user.id +
        '/' +
        this.storageService.user.currentFacilityId +
        '/' +
        this.storageService.authorizationToken +
        '/' +
        window.location.host.split('.')[0];
    } else {
      url =
        'http://localhost:4200/#/turf/beacon/map/' +
        this.storageService.user.id +
        '/' +
        this.storageService.user.currentFacilityId +
        '/' +
        this.storageService.authorizationToken +
        '/' +
        window.location.host.split('.')[0];
    }

    window.open(url, '_blank');
  }

  setHomePage() {
    // var value = document.getElementById('started').children[0].children[1][
    //   'value'
    // ];

    const value = this.selected_item || null;// Updating edit-profile page when user selecting my home page option
    this.requestService
      .send('PUT', `${endpoints.users}/${this.storageService.user.id}`, {
        body: {
          homepage: value,
        },
        headers: this.token,
      })
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });

      if(value) {
        this.router.navigateByUrl(value);
      }
  }

  checkModulePermission(module: string) {
    // if (!this.sidebarOpen) return;

    // if (this.navCollapse == module) {
    //   this.navCollapse = '';
    //   return;
    // }

    this.navCollapse = module;
    const mainModule = this.coreModules[module] as SidebarModule;
    for (const subModule of mainModule.subModules) {
      const isAuthorized = this.TurfService.isAuthorized(
        subModule.permission,
        'view'
      );
      if (!isAuthorized) continue;

      this.router.navigate([subModule.url]);
      return;
    }

    this.permissionDeniedService.show();
  }

  // function showinfoup() {
  //   openPopup = $uibModal.open({
  //     animation: $scope.animationsEnabled,
  //     templateUrl: './modules/core/views/modals/info.html',
  //     controller: 'information',
  //     windowClass: 'modal_info',
  //   });
  //   openPopup.result.then(function (url) {
  //     $localStorage.isTutorial = true;
  //     $location.path(url);
  //   }, function (err) {
  //     console.log(err);
  //   });
  // }

  // showTutorialForNewuserOnly = function () {
  //   if (this.storageService.user.id != null) {
  //     this.setFacilityForUser = function () {
  //       var id = this.storageService.user.currentFacilityId;

  //       this.requestService
  //       .send('PUT', `${endpoints.users}/${this.storageService.user.id}`, {
  //         body: {
  //           homepage: value,
  //         },
  //         headers: this.token,
  //       })
  //       .then((response) => {})
  //       .catch((error) => {
  //         console.log(error);
  //       });

  //       Facility.setCurrentFacility( {id: id})
  //       .$promise.then(function (res) {
  //         this.facilities.forEach(function (cf, index) {
  //           if (cf.id == id) {
  //             this.currentFacility.selected = cf.name;
  //             var user = this.storageService.user.getUser();
  //             user.currentFacilityId = id;
  //             user.facility = cf;
  //             this.storageService.user.setUser(user);
  //             $timeout(function () {
  //               this.storageService.user.location.reload();
  //             }, 300);
  //           }

  //         });
  //       }).catch(function (err) {
  //         console.log(err);
  //       });
  //     }
  //   }

  // }

  showTutorial() {
    let context = this;
    Showcaser.showcase(
      '<span class="text_welcome"><h1>Welcome to the Turf Assistant<h1></span> <h3 class="text_h3">Take a look at turf assistant tutorial.</h3>'
    );
    const select_itmes_1 = document.getElementById('select_itmes_1');
    const select_itmes_2 = document.getElementById('select_itmes_2');
    const select_itmes_3 = document.getElementById('select_itmes_3');

    // const showTute = document.getElementById("showTute");
    // const started = document.getElementById("started");
    document
      .getElementsByClassName('showcaser-skip')[0]
      .addEventListener('click', function () {
        context.requestService
          .send('PUT', `${endpoints.users}/${context.storageService.user.id}`, {
            body: {
              isTutorial: true,
            },
            headers: context.token,
          })
          .then((response) => {
            let user = context.storageService.user;
            user.isTutorial = true;
            context.storageService.user = user;
          })
          .catch((error) => {
            console.log(error);
          });
      });
    Showcaser.showcase(
      '<ul><li>This is the main area in Turf Assistant.</li><li>Plan section walks you through setting up a plan for your operations.</li><li>Execute is where you will spend most of your time. This is where your assistants live that will assist you in the execution of your plan.</li><li>Report is where you will find all your reports to measure how well you are executing your plan.</li></ul>',
      select_itmes_1,
      {
        backgroundColor: {
          r: 0,
          g: 0,
          b: 0,
          a: 0.75,
        },
        shape: 'rectangle',
        skipAll: function () {
          context.requestService
            .send(
              'PUT',
              `${endpoints.users}/${context.storageService.user.id}`,
              {
                body: {
                  isTutorial: true,
                },
                headers: context.token,
              }
            )
            .then((response) => {
              let user = context.storageService.user;
              user.isTutorial = true;
              context.storageService.user = user;
            })
            .catch((error) => {
              console.log(error);
            });
        },
      }
    );
    Showcaser.showcase(
      '<ul><li>Facebook UG is our community of Turf Assistant users. You can go here to network, ask questions and see what other folks are doing in their operations.</li><li>Apps will help you to download the Turf Assistant app on your mobile device or tablet. Simply enter your phone number and we will text you a link to download.</li><li>My Home Page will allow you to set a default homepage for logging into Turf Assistant. Superintendents and their Assistants usually choose Schedule Assistant while Equipment Technicians usually choose Equipment Assistant.</li></ul>',
      select_itmes_2,
      {
        backgroundColor: {
          r: 0,
          g: 0,
          b: 0,
          a: 0.75,
        },
        shape: 'rectangle',
        skipAll: function () {
          context.requestService
            .send(
              'PUT',
              `${endpoints.users}/${context.storageService.user.id}`,
              {
                body: {
                  isTutorial: true,
                },
                headers: context.token,
              }
            )
            .then((response) => {
              let user = context.storageService.user;
              user.isTutorial = true;
              context.storageService.user = user;
            })
            .catch((error) => {
              console.log(error);
            });
        },
      }
    );
    Showcaser.showcase(
      '<ul><li>The flag icon lets you change Turf Assistant to a different language. If you native language is not listed here contact us and we will work with you to translate it.</li><li>Your name drop down is your profile information. Here you can change your picture, reset your password and manage your account.</li></ul>',
      select_itmes_3,
      {
        backgroundColor: {
          r: 0,
          g: 0,
          b: 0,
          a: 0.75,
        },
        shape: 'rectangle',
        before: function () {
          context.requestService
            .send(
              'PUT',
              `${endpoints.users}/${context.storageService.user.id}`,
              {
                body: {
                  isTutorial: true,
                },
                headers: context.token,
              }
            )
            .then((response) => {
              let user = context.storageService.user;
              user.isTutorial = true;
              context.storageService.user = user;
            })
            .catch((error) => {
              console.log(error);
            });
        },
      }
    );
  }
}
