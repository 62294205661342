import { ErrorHandler, Injectable, ComponentRef } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { StorageService } from '../services/storage.serviceV2';
import { GlobalErrorPopupService } from './global-error-popup.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  syntaxError = 'is not valid json';
  constructor(
    private storageService: StorageService,
    private popupService: GlobalErrorPopupService
  ) {}

  async isContained(mainString) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const isContained = mainString.includes(this.syntaxError);
        resolve(isContained);
      }, 1000);
    });
  }

  async autoFixAndReload() {
    let fac = this.storageService?.user?.assignedFacilities.map((a) => {
      return a.facility;
    });

    localStorage.setItem('FACILITIES_LIST', JSON.stringify(fac) || '[]');
    localStorage.setItem('facilityList', JSON.stringify(fac) || '[]');

    console.clear();
    console.log('All values restored');
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  }

  async handleError(error: any) {

   

    if (error instanceof HttpErrorResponse) {
      // Handle HTTP errors
      console.error('Http Error:', error);
    } else {
      /**
       * This will reset below data in localstorage casuing white screen even after angular app has loaded
       * FACILITIES_LIST
       * facilityList
       * if user.assignedFacilities is null or undefined then user will be logged out automatically
       */

      if (
        (await this.isContained(error.toString().toLowerCase())) &&
        this.storageService?.user &&
        this.storageService?.user?.assignedFacilities &&
        Array.isArray(this.storageService?.user?.assignedFacilities)
      ) {
        const content = error.toString().toLowerCase();
        const filenameRegex = /(\w+\.\w{1,4})/g; // Regex to match a filename-like pattern

        const matches = content.match(filenameRegex);

        if (matches && matches.length > 0) {
          const filename = matches[0];
          console.log('Found filename:', filename);
        } else {
          console.log('No filename-like pattern found in the content.');
        }

        const dialogRef = this.popupService.openPopup(
          error.toString().toLowerCase()
        );
        dialogRef.afterClosed().subscribe(() => {
          // Handle any actions after the popup is closed if needed
          this.autoFixAndReload();
        });
      } else if(!this.storageService.user) {
        const dialogRef = this.popupService.openPopup(
          error.toString().toLowerCase()
        );
        dialogRef.afterClosed().subscribe(() => {
          localStorage.clear()
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        });
      }

      // Handle other types of errors
    }

    // Optionally rethrow the error to allow default error handling
    // throw error;
  }
}
