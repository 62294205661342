<div class="content-wrapper fadeInUp" style="margin-top: -4px"
*ngIf="storageService?.user?.id"
>
  <h3
    style="font-size: 18px; padding-top: 18px"
    [innerText]="'EditProfile' | translate"
  >
    Edit Profile
  </h3>
  <section>
    <div class="col-xs-12 col-md-offset-2 col-md-7 userbg userEditbg">
      <div class="row">
        <div class="circle circleEditprofile">
          <img
            src="../../../../assets/images//old-img/EditProfile_icon.png"
            class="animated zoomIn"
          />
        </div>
        <div class="col-lg-6 Editusercolbg" style="padding-top: 58px">
          <div class="circleUserprofile animated fadeInUp">
            <input
              id="uploader-button"
              type="file"
              accept="image/png, image/jpeg"
              (change)="onProfileChange($event)"
            />

            <label for="uploader-button">
              <div class="textuser">
                <i class="fa fa-camera" aria-hidden="true"></i>
              </div>
              <span class="img_uplode">
                <img
                  src="{{
                    user?.profilePic
                      ? imageURLPath + user?.profilePic
                      : profileBase64
                  }}"
                  class="animated"
                  id="proPic"
                  [ngStyle]="{
                    transform: 'rotate(' + user?.profilePicRotate + 'deg)'
                  }"
                />
              </span>
            </label>
          </div>
          <p
            class="welcome padding-top-8 welcome_2 padding-left-26 animated fadeIn"
          >
            {{ user?.firstname }} {{ user?.lastname }}
          </p>
        </div>

        <div class="col-lg-6 usercolbg2">
          <form
            class="float-left form_alignment padding-top-15 animated fadeInUp form-wrapper"
            name="userForm"
            id="userForm"
            autocomplete="off"
          >
            <div class="form-group mb-0 align">
              <div class="col-md-12 padding-left-5 padding-right-5">
                <div
                  class="lets padding-top-30"
                  [innerText]="'EditProfile' | translate"
                >
                  Edit Profile
                </div>
              </div>
              <div class="col-md-12 padding-left-5 padding-right-5">
                <input
                  tooltip-placement="right"
                  [tooltip]="'First Name'"
                  type="text"
                  name="firstname"
                  [(ngModel)]="user.firstname"
                  class="form-control margin_15 input_fildcell"
                  id="firstname"
                  placeholder="{{ 'FirstName' | translate }}"
                />
              </div>
            </div>
            <div class="form-group mb-0">
              <div class="col-md-12 padding-left-5 padding-right-5">
                <input
                  type="text"
                  id="lastname"
                  name="lastname"
                  [(ngModel)]="user.lastname"
                  class="form-control input_fildcell"
                  placeholder="{{ 'LastName' | translate }}"
                  tooltip-placement="right"
                  [tooltip]="'Last Name'"
                />
              </div>
            </div>
            <div class="form-group mb-0">
              <div
                class="col-md-12 col-sm-12 col-xs-12 padding-left-5 padding-right-5 padding-top-15"
              >
                <div
                  class="col-lg-12 col-md-12 col-sm-6 col-xs-12 padding-none"
                >
                  <div
                    class="nav navbar-nav navbar-left onboding_nav profile_onnav"
                  >
                    <ng-select
                      id="country"
                      [(ngModel)]="user.isdCode"
                      [clearable]="false"
                      [closeOnSelect]="true"
                      notFoundText="{{ 'Not found' }}"
                      bindLabel="name"
                      bindValue="callingCode"
                      [items]="countryList"
                      class="input-element form-input select-dropdown-menu ng-select-dropdown edit-profile-select"
                      [ngClass]="{
                        'is-invalid': submitted && userProfile.country.errors
                      }"
                      name="currency"
                      required
                      (change)="onCountryChange($event)"
                    >
                      <ng-template
                        ng-label-tmp
                        let-item="item"
                        let-clear="clear"
                        let-index="index"
                      >
                        <img
                          Class="flags"
                          alt=""
                          *ngIf="item.name != 'None'"
                          src="../../../../assets/images/flags/{{
                            user?.countryName
                          }}-32.png"
                        />

                        <!-- <span>{{ item.name }}</span> -->
                        <span class="ng-value-icon right" (click)="clear(item)"
                          >×</span
                        >
                      </ng-template>

                      <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-index="index"
                      >
                        <span *ngIf="index != 0">{{ item.callingCode }}- </span>
                        <span>{{ item.name }}</span>
                      </ng-template>
                    </ng-select>
                  </div>

                  <div class="controls inputcontre float-left">
                    <input
                      type="text"
                      [(ngModel)]="user.phone"
                      name="phone"
                      phone-input
                      class="form-control input_fildcell"
                      placeholder="Phone"
                      tooltip-placement="right"
                      [tooltip]="'Phone'"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="dateFormat-wrapper"
            >
              <label
                class="label-wrapper"
                for="dateFormat"
                >Date Format :</label>
              <ng-select
              class="dd-date-format-wrapper"
                [items]="dateFormatsList"
                bindValue="value"
                bindLabel="name"
                [(ngModel)]="user.dateFormat"
                [searchable]="false"
                >
                <ng-template ng-label-tmp let-item="item">
                  <span class="format-wrapper">{{ item?.name }}</span>
                </ng-template>
              </ng-select>
            </div>
            <div class="form-group mb-0">
              <div class="col-md-12 padding-left-5 padding-right-5 pt-3">
                <button
                  (click)="onProfileSubmit()"
                  type="button"
                  class="btn btn-default signinbtn margin-right-10 float-left"
                  [innerText]="'SubmitProfile' | translate"
                >
                  Submit Profile
                </button>
                <a
                  class="add-imgtext"
                  (click)="rotateImg(user?.profilePicRotate)"
                  >{{ 'rotate-image' | translate }}
                  <span class="rotatetext padding-left-5">
                    <i class="fa fa-rotate-right"></i></span
                ></a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</div>
