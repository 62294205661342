import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class FeatureService {

  
    isLocalizationOptIn = false;


   

}