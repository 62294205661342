import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { PagesComponent } from './pages/pages.component';
import { GlobalLayoutComponent } from './components/layouts/global/global-layout/global-layout.component';
import { HeaderComponent } from './components/layouts/header/header.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { SideNavigationComponent } from './components/layouts/side-navigation/side-navigation.component';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { provideEnvironmentNgxMask } from 'ngx-mask';
import { EditProfileComponent } from './pages/user-profile/edit-profile/edit-profile.component';
import { ChangePasswordComponent } from './pages/user-profile/change-password/change-password.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { QuickAssignedFacilityComponent } from './shared/quick-assigned-facility/quick-assigned-facility.component';



import { FeatureService } from './services/feature-service/feature.service';
import { FilterPipeModule } from 'ngx-filter-pipe';

import { ErrorHandler } from '@angular/core';
import { GlobalErrorHandler } from './global-error-handler/global-error-handler';
// import { ServiceWorkerModule } from '@angular/service-worker';
// import { environment } from '../environments/environment';
import { GlobalErrorComponent } from './global-error-handler/global-error.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { CUSTOM_LUXON_DATE_FORMATS, CUSTOM_LUXON_DATE_LOCALE, CustomLuxonDateAdapter, localeFactory } from './services/customDateAdapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

export const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'middle',
      distance: 12,
    },
    vertical: {
      position: 'top',
      distance: 12,
      gap: 10,
    },
  },
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 1,
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease',
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50,
    },
    shift: {
      speed: 300,
      easing: 'ease',
    },
    overlap: 150,
  },
};

@NgModule({ declarations: [
        PagesComponent,
        GlobalLayoutComponent,
        HeaderComponent,
        FooterComponent,
        SideNavigationComponent,
        EditProfileComponent,
        ChangePasswordComponent,
        QuickAssignedFacilityComponent,
        GlobalErrorComponent
        // OnBoardingComponent,
        // HeaderDown
    ],
    bootstrap: [PagesComponent], imports: [BrowserModule,
        AppRoutingModule,
        SharedModule,
        BrowserAnimationsModule,
        GoogleMapsModule,
        ToastrModule.forRoot(),
        // NgMaskModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        NotifierModule.withConfig(customNotifierOptions),
        // NgxEditorModule
        BsDatepickerModule.forRoot(),
        ModalModule.forRoot(),
        CollapseModule.forRoot(),
        TabsModule.forRoot(),
        TooltipModule.forRoot(),
        BsDropdownModule.forRoot(),
        NgSelectModule,
        FilterPipeModule,
        // ServiceWorkerModule.register('ngsw-worker.js', {
        //   enabled: environment.production,
        //   // Register the ServiceWorker as soon as the application is stable
        //   // or after 30 seconds (whichever comes first).
        //   registrationStrategy: 'registerWhenStable:30000'
        // }),
        MatDatepickerModule,
        MatInputModule,
        MatNativeDateModule], providers: [
        BsModalRef,
        FeatureService,
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        provideEnvironmentNgxMask(),
        { provide: DateAdapter, useClass: CustomLuxonDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: CUSTOM_LUXON_DATE_FORMATS },
        { provide: MAT_DATE_LOCALE, useFactory: localeFactory, deps: [CUSTOM_LUXON_DATE_LOCALE] },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
