<!-- <div class="payment-pending-container">
  <p class="payment-pending-text">Payment payment from the last 26 days</p>
  <i class="fas fa-times close-icon"></i>

</div> -->

<app-header *ngIf="!hideHeader"></app-header>

<app-side-navigation
  *ngIf="!hideSidebar"
></app-side-navigation>
<div class="main-content" [ngStyle]="{
    'margin-top': hideHeader ? '0px' : 'undefined',
    'padding-left': 
    hideSidebar ? '0px' :
      !(appService.sidebarOpen | async) 
    || ( 
      ( 
       isTabletView
      )
      && (appService.sidebarOpen | async) 
    )
      ? '70px' 
    : undefined
  }" style="padding-bottom: 50px;">



  <router-outlet></router-outlet>
</div>
<app-footer></app-footer>