import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { RequestService } from '../../../services/request.service';
import { ToastrService } from 'ngx-toastr';
import { endpoints } from '../../../constants/endpoints';
import { Router } from '@angular/router';
import { StorageService } from '../../../services/storage.serviceV2';
import { HelperData } from '../../../constants/helper-data';
import { LoaderService } from 'src/app/services/common-helper-service/loder.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent implements OnInit {
  form: UntypedFormGroup;
  submitted = false;
  editorConfig;
  loggedInUser: any = {};
  editable: any = [];
  spellcheck: any = [];

  currencyList: any = [];
  countryList: any = [];
  currency: any = {};
  country: any = {};
  token: any;

  userForm: UntypedFormGroup;
  facilityForm: UntypedFormGroup;
  userProfileSubmitted = false;
  public setupUser: String = 'userProfile';

  currentTab: string = 'user';
  unitMeasureList: any;

  html: any = '<h1>Nafees</h1>';

  user: any = {};
  fileUrlS3;
  profilePicRotates;
  profileForm;

  facilityImageForm;
  facilityImageFile;
  imageURLPath: any = environment.imageURLPath;

  profileBase64: any = '../../../../../../assets/images/man.png';

  dateFormatsList = [
    { name: 'yyyy-mm-dd', value: 'yyyy-MM-dd' },
    { name: 'dd-mm-yyyy', value: 'dd-MM-yyyy' },
    { name: 'mm-dd-yyyy', value: 'MM-dd-yyyy' },
    { name: 'dd/mm/yyyy', value: 'dd/MM/yyyy' },
    { name: 'mm/dd/yyyy', value: 'MM/dd/yyyy' },
    { name: 'yyyy/mm/dd', value: 'yyyy/MM/dd' },
    { name: 'dd.mm.yyyy', value: 'dd.MM.yyyy' },
    { name: 'mm.dd.yyyy', value: 'MM.dd.yyyy' },
    { name: 'yyyy.mm.dd', value: 'yyyy.MM.dd' },
  ]

  constructor(
    private requestService: RequestService,
    private toastr: ToastrService,
    public storageService: StorageService,
    private loaderService: LoaderService,
    private translate: TranslateService
  ) {
    this.editorConfig = HelperData.getEditorConfig();
    this.currencyList = HelperData.getCurrency();
    this.countryList = HelperData.getPhone();
    this.loggedInUser = this.storageService.user;

    this.token = {
      authorization: this.storageService.authorizationToken,
    };
    this.user = this.storageService.user;

  }

  get userProfile() {
    return this.userForm.controls;
  }

  onProfileChange(event) {
    var render = new FileReader();
    var self = this;
    render.addEventListener(
      'load',
      function () {
        self.profileBase64 = render.result;
      },
      false
    );
    render.readAsDataURL(event.target.files[0]);
    let fileList: FileList = event.target.files;
    let file: any = fileList[0];
    var fileExtension = '.' + file.name.split('.').pop();
    var timeStamp = Math.floor(Date.now() / 1000);
    let filename = timeStamp + fileExtension;
    this.profileForm = new FormData();
    this.profileForm.append('filename', file, filename);

    this.user.profilePic = null;
  }

  get f() {
    return this.form.controls;
  }
  ngOnInit(): void {

  


    this.userForm = new UntypedFormGroup({
      firstName: new UntypedFormControl('', [Validators.required]),
      lastName: new UntypedFormControl('', [Validators.required]),
      country: new UntypedFormControl('', [Validators.required]),
      phoneNumber: new UntypedFormControl('', [Validators.required]),
    });

    this.userForm.controls['country'].setValue({
      name: 'United States',
      code: 'US',
      callingCode: '+1',
    });

    this.facilityForm = new UntypedFormGroup({
      country: new UntypedFormControl('', [Validators.required]),
      fastName: new UntypedFormControl('', [Validators.required]),
      lastName: new UntypedFormControl('', [Validators.required]),
      phon: new UntypedFormControl('', [Validators.required]),
    });
  }
  handleAddressChange(address) {
    this.facilityForm.controls['address'].setValue(address['name']);
    this.facilityForm.controls['lat'].setValue(
      address['geometry']['location'].lat()
    );
    this.facilityForm.controls['lng'].setValue(
      address['geometry']['location'].lng()
    );

    address['address_components'].forEach((item, idx) => {
      let name = item['long_name'];
      if (item.types.indexOf('postal_code') > -1)
        this.facilityForm.controls['zip'].setValue(name);
      else if (item.types.indexOf('country') > -1)
        this.facilityForm.controls['country'].setValue(name);
      else if (item.types.indexOf('administrative_area_level_1') > -1)
        this.facilityForm.controls['state'].setValue(name);
      else if (item.types.indexOf('locality') > -1)
        this.facilityForm.controls['city'].setValue(name);
    });
  }
  errorHandler(error) {
    this.loaderService.hideLoader();
    this.translate.get('SomethingWentWrong').subscribe((message) => {
      this.toastr.error(message, this.loaderService.notificationTitle.error);
    });
    console.log(error);
  }

  onUserFormSubmit() {
    // let data = {
    //   isdCode: this.user.isdCode,
    //   phone: this.user.phone,
    //   lastname: this.user.lastname,
    //   firstname: this.user.firstName,
    //   countryName: this.user.countryName,
    //   profilePic:
    // };

    console.log(this.user);
    this.loaderService.showLoader();
    this.requestService
      .send('PUT', `${endpoints.users}/${this.loggedInUser.id}`, {
        body: this.user,
        headers: this.token,
      })
      .then((response: any) => {
        this.loaderService.hideLoader();

        let facilities = this.storageService.allFacilityList;
        response.facilities = facilities;
        this.storageService.user = response;
        this.translate.get('ProfileSavedSuccessfully').subscribe((message) => {
          this.toastr.success(
            message,
            this.loaderService.notificationTitle.success
          );
        });
        location.reload();
        setTimeout(() => {}, 1000);
      })
      .catch((error) => {
        this.loaderService.hideLoader();
        this.errorHandler(error);
      });
  }

  onSubmit() {
    this.submitted = true;
  }

  onProfileSubmit() {
    // if(this.user.sprayLicense && this.user.sprayLicense<6) {
    //   return this.toaster.error('Should be 6 character')

    // }

    if (this.profileForm) {
      this.loaderService.showLoader();
      this.requestService
        .send('POST', `${endpoints.profileImageUpload}`, {
          body: this.profileForm,
          headers: this.token,
        })
        .then((response: any) => {
          this.user['profilePic'] =
            response['result']['files']['filename'][0]['name'];
          this.user['profilePicRotate'] = this.user.profilePicRotate;
          this.loaderService.hideLoader();
          this.onUserFormSubmit();
        })
        .catch((error) => {
          this.loaderService.hideLoader();
          console.log(error);
        });
    } else {
      this.onUserFormSubmit();
      this.loaderService.showLoader();
    }
  }

  rotateImg(rotate) {
    if (!rotate) rotate = 0;
    this.user.profilePicRotate = rotate == 270 ? 0 : rotate + 90;
  }

  onCountryChange($event) {
    this.user.countryName = $event.code;
  }

  upload() {}
}
