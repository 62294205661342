// popup-service.ts
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GlobalErrorComponent } from './global-error.component';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorPopupService {
  constructor(private dialog: MatDialog) {}

  openPopup(dataToSend: any): MatDialogRef<GlobalErrorComponent> {
    return this.dialog.open<GlobalErrorComponent, any, any>(GlobalErrorComponent, {
      width: '1700px',
      height: '100%',
      panelClass:"mat-panel",
      disableClose: true,
      data: dataToSend // Pass the data as part of the configuration
    });
  }

  closePopup() {
    this.dialog.closeAll();
  }
}
