import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { RequestService } from '../../../services/request.service';
import { ToastrService } from 'ngx-toastr';
import { endpoints } from '../../../constants/endpoints';
import { Router } from '@angular/router';
import { StorageService } from '../../../services/storage.serviceV2';
import { HelperData } from '../../../constants/helper-data';
import { LoaderService } from 'src/app/services/common-helper-service/loder.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  form: UntypedFormGroup;
  submitted = false;
  editorConfig;
  loggedInUser: any = {};
  editable: any = [];
  spellcheck: any = [];

  currencyList: any = [];
  countryList: any = [];
  currency: any = {};
  country: any = {};
  token: any;
  userForm: UntypedFormGroup;
  facilityForm: UntypedFormGroup;
  userProfileSubmitted = false;
  public setupUser: String = 'userProfile';

  passwordDetails: any = {};

  constructor(
    private requestService: RequestService,
    private toastr: ToastrService,
    private storageService: StorageService,
    private loaderService: LoaderService,
    private translate: TranslateService
  ) {
    this.editorConfig = HelperData.getEditorConfig();
    this.currencyList = HelperData.getCurrency();
    this.countryList = HelperData.getPhone();
    this.loggedInUser = this.storageService.user;

    this.token = {
      authorization: this.storageService.authorizationToken,
    };
  }

  get f() {
    return this.form.controls;
  }
  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      firstName: new UntypedFormControl('', [Validators.required]),
      lastName: new UntypedFormControl('', [Validators.required]),
      country: new UntypedFormControl('', [Validators.required]),
      phoneNumber: new UntypedFormControl('', [Validators.required]),
    });
  }
  success;
  error;

  updateUser(data, id) {
    this.requestService
      .send('PUT', `${endpoints.users}/${id}`, {
        body: data,
        headers: this.token,
      })
      .then((response) => {
        this.loaderService.hideLoader();
        this.translate
          .get('PasswordChangedSuccessfully')
          .subscribe((message) => {
            this.toastr.success(
              message,
              this.loaderService.notificationTitle.success
            );
          });
        this.passwordDetails.currentPassword = '';
        this.passwordDetails.newPassword = '';
        this.passwordDetails.verifyPassword = '';
        this.success = '';
      })
      .catch((error) => {
        this.errorHandler(error);
      });
  }

  changeUserPassword() {
    this.success = this.error = null;

    if (
      this.passwordDetails &&
      this.passwordDetails.newPassword &&
      this.passwordDetails.verifyPassword &&
      this.passwordDetails.currentPassword
    ) {
      if (
        this.passwordDetails.newPassword != this.passwordDetails.verifyPassword
      ) {
        this.error = 'Verify password do not match!';
        return;
      } else {
        this.loaderService.showLoader();

        this.requestService
          .send('POST', `${endpoints.changeUserPassword}`, {
            body: {
              id: this.storageService.user.id,
              data: this.passwordDetails,
            },
            headers: this.token,
          })
          .then((response) => {
            this.success = 'Password verified!';
            this.updateUser(
              { password: this.passwordDetails.newPassword },
              this.storageService.user.id
            );
          })
          .catch((error) => {
            this.loaderService.hideLoader();
            this.toastr.error(
              error?.error?.error?.message || 'Oops! Something went wrong',
              'Error'
            );
          });
      }
    } else {
      this.error = 'Please fill all the fields!';
      return;
    }
  }

  errorHandler(error) {
    this.loaderService.hideLoader();
    console.log(error);
  }
}
