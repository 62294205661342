<div class="content-wrapper fadeInUp" style="margin-top: -4px">
  <h3
    style="font-size: 18px; padding-top: 18px"
    [innerText]="'ChangePassword' | translate"
  >
    Change Password
  </h3>

  <section>
    <div
      class="col-lg-6 col-md-6 col-sm-6 col-xs-12 box col-md-offset-3 col-sm-offset-3"
      style="margin-top: 5%"
    >
      <div class="panel panel-primary box-primary">
        <div class="panel-heading p-2">
          <div class="col-md-6 padding-left">
            <h3
              class="panel-title text-left"
              [innerText]="'ChangePassword' | translate"
            >
              Change Password
            </h3>
          </div>
          <div class="col-md-6 padding-right">
            <ul class="cel_arrowbg">
              <!-- <li><a href="javascript:void(0)"><i class="fa fa-chevron-down" aria-hidden="true"></i></a></li> -->
              <!-- <li><a tooltip-placement="left" uib-tooltip="{{'FullScreen' | translate}}" ng-click="app.layout.isCollapsed = !app.layout.isCollapsed"><i class="fa fa-arrows-alt" aria-hidden="true"></i></a></li>
          <li><a ng-click="find()" tooltip-placement="left" uib-tooltip="{{'Refresh' | translate}}"><img src="../images/icontab_ref.png"></a></li> -->
            </ul>
          </div>
        </div>
        <div class="panel-body p-0">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0">
            <!-- <form class="signin form-horizontal"
             [FormGroup]="form" 
             (ngSubmit)="changeUserPassword()" 
             autocomplete="off"> -->

            <div class="pull-left w-100" style="margin-bottom: 0px">
              <div class="form-group align">
                <div class="col-md-12">
                  <div
                    class="letschen pt-2 pb-3"
                    [innerText]="'ChangeAccountPassword' | translate"
                  >
                    Change Account Password
                  </div>
                </div>
                <div class="col-md-12">
                  <p
                    class="text-paragraph pb-4 pt-2"
                    [innerText]="'Pleaseenteryournewpassword' | translate"
                  >
                    Please enter your new password.
                  </p>
                </div>
                <div class="col-md-12">
                  <label
                    for="CurrentPassword"
                    [innerText]="'CurrentPassword' | translate"
                    >Current Password</label
                  >
                  <input
                    type="password"
                    id="currentPassword"
                    tooltip-placement="right"
                    class="form-control"
                    [(ngModel)]="passwordDetails.currentPassword"
                    placeholder="{{ 'CurrentPassword' | translate }}"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 margin-top-5">
                  <label
                    for="newPassword"
                    [innerText]="'NewPassword' | translate"
                    >New Password</label
                  >
                  <input
                    type="password"
                    id="newPassword"
                    tooltip-placement="right"
                    class="form-control"
                    [(ngModel)]="passwordDetails.newPassword"
                    placeholder="{{ 'NewPassword' | translate }}"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 margin-top-5">
                  <label
                    for="verifyPassword"
                    [innerText]="'VerifyPassword' | translate"
                    >Verify Password</label
                  >
                  <input
                    type="password"
                    id="verifyPassword"
                    name="verifyPassword"
                    class="form-control"
                    [(ngModel)]="passwordDetails.verifyPassword"
                    placeholder="{{ 'VerifyPassword' | translate }}"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 padding-top-15">
                  <button
                    (click)="changeUserPassword()"
                    type="button"
                    class="btn btn-default signinbtn margin-right-30"
                  >
                    <i class="fa fa-edit"></i> {{ 'Update' | translate }}
                  </button>
                </div>
              </div>
              <div [hidden]="!success" class="text-success">
                <strong>{{ success }}</strong>
              </div>
              <div [hidden]="!error" class="text-danger">
                <strong>{{ error }}</strong>
              </div>
            </div>
            <!-- </form> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
